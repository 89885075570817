import React from 'react'
import Container from '../../components/container'
import colors from '../../utils/colors'
import { Button, FormFeedback, Form, FormGroup, Label, Input } from 'reactstrap'
import Layout from '../../components/layout';
import { graphql, StaticQuery } from 'gatsby'

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

const styles = {
    primary: {
        WebkitTransition: `all 0.3s`,
        MozTransition: `all 0.3s`,
        transition: `all 0.3s`,
        background: colors.darkPrimaryColor,
        color: `#FFF`,
        marginTop: `1rem`,
        borderColor: `#FFF`,
        ":hover": {
            background: `#FFF`,
            color: colors.darkPrimaryColor
        }
    }
}

class SignUp extends React.Component {

    constructor(props) {
        super(props)
        this.state = { firstName: '', lastName: '', email: '', organization: '' }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit = e => {
        fetch("/thanks-volunteer/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "signup", ...this.state })
        })
            .then((response) => window.location.replace(response.url))
            .catch(error => alert(error))

        e.preventDefault();
    }

    render() {
        return (
            <StaticQuery query={
                graphql`
                    query VolunteerPageQuery {
                        datoCmsVolunteerSignupPage {
                            volunteerFormHeader
                        }

                    }`
            }
                render={(data) => (
                    <Layout location={this.props.location}>
                        <Container css={{ display: `flex`, alignItems: `center`, flexDirection: `column`, marginLeft: `16rem`, marginTop: `3.5rem`, flex: `1 0 80%` }}>
                            <h1 style={{ borderBottom: `1px solid black`, textAlign: `center`, width: `50%`, color: `${colors.darkPrimaryColor}` }}>{data.datoCmsVolunteerSignupPage.volunteerFormHeader}</h1>
                            <Form style={{ display: `flex`, alignSelf: `center`, flexDirection: `column`, justifyContent: `center`, alignItems: `center` }} name="signup"
                                method="post"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                onSubmit={this.handleSubmit}
                                action="/thanks-volunteer">
                                <p hidden>
                                    <label>
                                        Don’t fill this out: <input name="bot-field" />
                                    </label>
                                </p>
                                <FormGroup>
                                    <Label for="firstName">First Name</Label>
                                    <Input
                                        type={'text'}
                                        title={'First name'}
                                        invalid={this.state.firstName ? false : true}
                                        onChange={this.handleChange}
                                        name={'firstName'}
                                        placeholder={''}
                                    />
                                    <FormFeedback>Please enter your first name.</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="lastName">Last Name</Label>
                                    <Input
                                        type={'text'}
                                        title={'Last name'}
                                        name={'lastName'}
                                        invalid={this.state.lastName ? false : true}
                                        onChange={this.handleChange}
                                        placeholder={''}
                                    />
                                    <FormFeedback>Please enter your last name.</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input
                                        type={'text'}
                                        title={'Email'}
                                        name={'email'}
                                        onChange={this.handleChange}
                                        invalid={this.state.email ? false : true}
                                        placeholder={''}
                                    />

                                    <FormFeedback>Please enter a valid email address.</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="organization">Organization  (optional)</Label>
                                    <Input
                                        type={'text'}
                                        title={'Organization (optional, for student volunteers)'}
                                        name={'organization'}
                                        onChange={this.handleChange}
                                        placeholder={''}
                                    />
                                </FormGroup>

                                <p>
                                    <Button size="lg" type="submit" style={styles.primary} disabled={!this.state.email || !this.state.firstName || !this.state.lastName || !this.state.organization}>
                                        Submit
                            </Button>
                                </p>
                            </Form>
                        </Container>
                    </Layout>
                )}
            />
        )

    }
}

export default SignUp
